// Assign module to Astral object
Astral.shuffle = {
	init: function(){
		$('div.astral-shuffle').astralShuffle({
			pager: 'div.comp-pager',
			speed: 8000,
			spacing: 30
		});

		// Handle Project Links
		Astral.shuffle.handleProjectLinks();
	},

	/**
	 * Handle Project Links
	 * This function manages clicking an active link by directing it to the projects page
	 */
	handleProjectLinks: function(){
		// Enable a click function for the active item(both header/image)
		$('div.astral-shuffle').find('div.headings').add('div.images').on('click', 'li.active a', function(e){
			var oProjectNavItem = $('#navigation').find('a[href="/projects"]'),
				sProjectID = $(this).attr('href').slice(10),
				oExpandable = $('#' + sProjectID);

			// Add data item
			oProjectNavItem.data('project', sProjectID);

			// Trigger link
			oProjectNavItem.trigger('click');

			// Trigger Expandable if not already opened
			if(!oExpandable.hasClass('open')){
				// Use the 'history' flag rather than create a brand new variable that does the same thing
				// This prevents the app from updating the url twice making the browser's back button appear to be 'broken'
				oExpandable.data('history', true).find('header.trigger').trigger('click');
			}

			e.preventDefault();
		});
	}
}

// Register this module with Astral
Astral.core.register({
	name: 'Shuffle Gallery', // User friendly name used to reference it person-to-person
	type: 'module', // module || utility
	functionName: 'shuffle', // Code friendly name using camelCase
	subscribers: { // add any number of custom events usting the same name/array convention found below, fill arrays with function names from the module
		'pre': [], // pre-init
		'dom': ['init'], // document ready
		'post': [], // post-init
		'load': [] // all resources loaded
	},
	dependencies: [] // Array of strings referencing functionNames of other modules/utilities (optional)
});
