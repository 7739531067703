// Assign module to Astral object
Astral.projectFilters = {
	init: function(){
		var _Module = Astral.projectFilters;

		// Set up change handler
		$('ul.jsFilters').on('change', 'input', function(e){
			var oInput = $(this),
				oProjects = $('#projects').find('section.expandable'),
				oSelectedFilters = _Module.getSelectedFilters(),
				hasNoResults = true;

			// Handle Subfilter Display
			if(oInput.closest('ul').attr('id') == 'filter-type'){
				_Module.handleSubfilterDisplay(oInput.val());
			}

			// Cycle through Projects
			$.each(oProjects, function(i){
				var oProject = $(this),
					sCategories = oProject.data('categories'),
					iCategoryCount = oSelectedFilters.categories.length,
					isValid = true;

				// Verify type
				if(oSelectedFilters.type == 'all' || oSelectedFilters.type == oProject.data('type')){

					if(iCategoryCount > 0){
						var iMatchedCategories = 0;

						// Cycle thru categories
						$.each(oSelectedFilters.categories, function(j){
							var sCategory = this;

							// Verify it matches a category
							if(sCategories.indexOf(sCategory) > -1){
								iMatchedCategories++;
							}
						});

						// if project hasn't matched all selected categories then it isn't valid
						if(iCategoryCount != iMatchedCategories){ 
							isValid = false;
						}else{
							hasNoResults = false;
						}
					}else{
						hasNoResults = false;
					}
				}else{
					isValid = false;
				}

				// Close Project if its opened but fails validation
				if(!isValid && oProject.hasClass('open')){
					oProject.find('header.trigger').trigger('click');
				}

				// Handle visibility
				// Safari appears to have an animation-fill-mode bug or is otherwise imporperly handling the removal of classes causing either a re-fade, 0% opacity or a bouncy glitch depending on the methodology to 'fix' the issue. Rather than hindering the better, and more used browsers, we simply change the way the animation functions on safari.
				if(!astralDetect.browser('safari')){
					if(isValid && oProject.hasClass('isHidden')){
						_Module.handleProjectClasses(oProject, 'reveal');
					}else if(!isValid && !oProject.hasClass('isHidden')){
						_Module.handleProjectClasses(oProject, 'hide');
					}
				}else{
					if(isValid && oProject.hasClass('isHidden')){
						oProject.removeClass('isHidden');
					}else if(!isValid && !oProject.hasClass('isHidden')){
						oProject.addClass('isHiding');
					}
				}
			});

			// Check if no results
			_Module.handleNoResultsDisplay(hasNoResults);
		});

		_Module.intiateFilteStatenHandlers();
		_Module.intiateMobileFilters();
	},

	/**
	 * Handle No Results Display
	 * If there are no visible projects, meaning no project matches all filters, show a message.
	 *
	 * @param {bool} hasNoResults [determined if no results were available in loop during filtering phase]
	 */
	handleNoResultsDisplay: function(hasNoResults){
		var oMessage = $('#no-results');

		if(hasNoResults){
			oMessage.removeClass('hidden');
		}else{
			oMessage.addClass('hidden');
		}
	},

	/**
	 * Handle Subfilter Display
	 * This function will toggle the necessary classes depending on the value and current display of the subfilters
	 * 
	 * @param  {string} type [the selected value of the main filter]
	 */
	handleSubfilterDisplay: function(type){
		var oSubfilters = $('#subfilters'),
			oModal = $('body > .astral-modal-container');

		// Reveal Filters
		if(type == 'website'){

			// if in modal just instantly show for mobile users
			if(oModal.length > 0){
				oSubfilters.removeClass('hidden');
			}else if(oSubfilters.hasClass('hidden')){
				oSubfilters.removeClass('hidden').addClass('isRevealing');

				// Manage classes after transition
				setTimeout(function(){
					oSubfilters.removeClass('isRevealing');
				}, 350);
			}

		// Hide filters
		}else{
			// if in modal just instantly hide for mobile users
			if(oModal.length > 0){
				oSubfilters.addClass('hidden');				
			}else if(!oSubfilters.hasClass('hidden')){
				oSubfilters.addClass('isHiding');

				// Manage classes after transition
				setTimeout(function(){
					oSubfilters.addClass('hidden').removeClass('isHiding');
				}, 350);
			}
		}
	},

	/**
	 * Initiate Filter State Handlers
	 * This function creates various animation event handlers to swap states
	 */
	intiateFilteStatenHandlers: function(){
		var oFilters = $('#filters');

		// Events based on when an animation ends
		oFilters.on(Astral.vars.animationEvents.end, function(e){
			// Only continue when oFilters is the source of the animation end
			if($(e.originalEvent.target).attr('id') != 'filters'){return;}

			// Change various things based on animation that ends
			switch(e.originalEvent.animationName){
				case 'animateFade':
					if(oFilters.hasClass('isRevealing')){
						oFilters.removeClass('isRevealing');
					}else{
						oFilters.addClass('hidden');
					}
					break;
			}
		});
	},

	/**
	 * Initiate Modal Filters Functions
	 * This function sets up the filter button and modal handling for phablet and below devices
	 */
	intiateMobileFilters: function(){     
		 $('#btn-filters').on('click', function(e){
			var oBtn = $(this),
				oFilters = $('#filters'),
				oModal = $('#modal-filters'),
				oSubfilters = $('#subfilters');

			// Move filters into modal
			oFilters.detach().appendTo(oModal.find('div.modal-content'));

			// Scroll to top for consistency because different browsers handle the overflow a bit diff on top of different device/browser heights makes it nigh impossible to stay 'centered' let alone trying to figure out 'center' when the filter 'removes' projects from view causing a shift in height
			$('html,body').animate({
				scrollTop: 0
			}, 250);

			//Open Modal
			oModal.astralModal({
				classes: 'animated isForcedTop',
				callbackClosed: function(){
					oFilters.detach().prependTo($('#page-projects').find('div.sidebar'));
				}
			});

			e.preventDefault();
		});
	},

	/**
	 * Get Selected Filters
	 * This function retrieves all selected filters
	 */
	getSelectedFilters: function(){
		var sType = $('input[name="type"]:checked').val(),
			oSubfilters = $('#subfilters').find('li'),
			oCategories = [];

		// Create array of checked values
		$.each(oSubfilters, function(i){
			var oLI = $(this),
				oCategoryFilter = oLI.find('input:checked');

			// Only get filters that match the type at the moment
			if(oLI.data('type') == sType && oCategoryFilter.length > 0){
				oCategories.push(oCategoryFilter.val());
			}
		});

		return {
			type: sType,
			categories: oCategories
		}
	},

	/**
	 * Handle Project Classes
	 * This function adds/removes classes as needed from projects as they are revealed/hidden so that cross-classes don't interfer with each other.
	 * 
	 * @param  {jQuery obj} oProject [the specific expandable to modify]
	 * @param  {str} newState [reveal or hide]
	 */
	handleProjectClasses: function(oProject, newState){
		var oClasses = {
				hide: 'isHiding',
				reveal: 'isRevealing'
			};

		// Add corresponding class
		oProject.addClass(oClasses[newState]);
	}
}

// Register this module with Astral
Astral.core.register({
	name: 'Project Filters', // User friendly name used to reference it person-to-person
	type: 'module', // module || utility
	functionName: 'projectFilters', // Code friendly name using camelCase
	subscribers: { // add any number of custom events usting the same name/array convention found below, fill arrays with function names from the module
		'pre': [], // pre-init
		'dom': ['init'], // document ready
		'post': [], // post-init
		'load': [] // all resources loaded
	},
	dependencies: [] // Array of strings referencing functionNames of other modules/utilities (optional)
});
