// Assign module to Astral object
Astral.router = {
	init: function(){
		var _Module = Astral.router;

		// Initiate Navigation
		_Module.initiateMainNavigation();

		// Initiate Header States
		_Module.initiateHeaderStateHandlers();

		// Handle Page Transitions
		_Module.handlePageTransitions(window.location.pathname, true);

		// Handle History States
		_Module.handleHistoryStates();
	},

	/**
	 * Initiate Header State Handlers
	 * This function is dedicated to header specific transition/state changes. However, all states are tied to the HTML element in case other elements need to be able to respond to a specific state
	 */
	initiateHeaderStateHandlers: function(){
		// Remove transitioning class
		$('#header').on(Astral.vars.animationEvents.end, function(e){
			var oHTML = $('html');

			switch(e.originalEvent.animationName){
				case 'animateHeaderFadeIn':
					// Need a delay here, unsure why other than possibly too many animations running at once throwing off the timing b/w JS/CSS 
					setTimeout(function(){
						oHTML.removeClass('pageState-isLoaded headerState-isSticking headerState-isFading');
					}, 250);
					break;
				case 'animateHeaderPosition':
					if(oHTML.hasClass('headerState-isSidebaring')){
						oHTML.removeClass('headerState-isSidebaring headerState-isStuck').addClass('headerState-isFading');
					}
					break;
			}
		});
	},

	/**
	 * Handle Page Transitions
	 * This function 
	 * 
	 * @param  {string} url [the url of the page]
	 * @param  {boolean} isPageLoad [only passed on initial load]
	 */
	handlePageTransitions: function(url, isPageLoad, isHistory){
		var oHTML = $('html'),
			oPage = $('#page-home'), // assume home page is default
			oFilterBtn = $('#btn-filters'),
			oProjectCloseBtn = $('#btn-close_project'),
			oOpenExpandable,
			isProject = url.indexOf('/projects/') > -1 ? true : false,
			isResume = url.indexOf('/resume') > -1 ? true : false,
			isPhabletOrPhone = astralDetect.breakpoint('< ' + Astral.vars.breakpoints.tablet) ? true : false;

		// Run various events based on new active page
		if(!isProject){

			switch(url){
				case '/projects':
					oOpenExpandable = $('section.expandable.open');

					oPage = $('#page-projects');

					// Hide filters button
					oFilterBtn.removeClass('hidden');
					break;
				case '/about':
					oPage = $('#page-about');

					// Animate sections in by applying a class with a delay
					$.each(oPage.find('div.section'), function(i){
						var oSection = $(this);

						setTimeout(function(){
							oSection.addClass('animateIn');
						}, (i+1) * 150);
					});

					// Hide filters button
					oFilterBtn.add(oProjectCloseBtn).addClass('hidden');

					break;
				case '/contact':
				case '/contact/resume':
					oPage = $('#page-contact');

					// Hide filters button
					oFilterBtn.add(oProjectCloseBtn).addClass('hidden');
					break;
				case '/':
				default: 
					// Reverse process of becoming topbar but not when page is loading
					if(!oHTML.hasClass('pageState-isLoading')){
						// Remove all classes rather than triggering animation causing a hiccup
						if(isPhabletOrPhone){
							oHTML.removeClass('headerState-isSidebaring headerState-isSticking headerState-isStuck');
						}else{
							oHTML.addClass('headerState-isSidebaring');
						}
					}

					// Hide filters button
					oFilterBtn.add(oProjectCloseBtn).addClass('hidden');

					break;
			}
		}else{
			var sProjectID = url.slice(10),
				oExpandable = $('#' + sProjectID);

			// Set Page
			oPage = $('#page-projects');

			// Reset URL
			url = '/projects';
		}

		// Activate page
		oPage.addClass('isActive').siblings().removeClass('isActive');

		// Apply topbar class when user clicks off home page but not if it's already stuck
		if(oPage.attr('id') != 'page-home' && !oHTML.hasClass('headerState-isStuck')){
			if(isPhabletOrPhone){
				oHTML.addClass('headerState-isStuck');
			}else{
				oHTML.addClass('headerState-isSticking headerState-isStuck');
			}
		}

		// Remove About animation classes
		if(oPage.attr('id') != 'page-about'){
			$('#page-about').find('div.section').removeClass('animateIn');
		}

		// Trigger various events if page is loading
		if(isPageLoad){

			// Swap loading and loaded classes
			oHTML.removeClass('pageState-isLoading').addClass('pageState-isLoaded');

			// Open Resume
			if(isResume){
				url = '/contact';

				// Open modal
				oPage.find('a.launch-resume_modal').trigger('click', [true]);
			}

			// Trigger a click but pass a flag to not retrigger handlePageTransitions
			$('#navigation').find('a[href="'+ url +'"]').trigger('click', [true]);
			
			// Trigger Expandable
			if(oExpandable){
				oExpandable.find('header.trigger').trigger('click');
			}
		}else{
			// Scroll to Top in case other pages force user down but not if history navigation on certain pages
			if($('body').data('history')){
				$('body').data('history', false);

				if(oExpandable && oExpandable.hasClass('open')){
					// Scroll to Expandable
					$('html,body').animate({
						scrollTop: oExpandable.offset().top - 80
					}, 250);
					oProjectCloseBtn.removeClass('hidden');
				}
			}else{
				// Scroll to Expandable if open
				if(oOpenExpandable && oOpenExpandable.length == 1){
					$('html,body').animate({
						scrollTop: oOpenExpandable.offset().top - 80
					}, 250);
					oProjectCloseBtn.removeClass('hidden');
				}else{
					$('html,body').animate({
						scrollTop: 0
					}, 250);
				}
			}
		}
	},

	/**
	 * Initiate Main Navigation Functions
	 * This function sets up the main navigation and adjusting classes while calling the initial loaded functions
	 */
	initiateMainNavigation: function(){
		var _Module = Astral.router,
			oNavigation = $('#navigation'),
			sActiveClass = 'isActive';

		// Navigation click events with a boolean to not recall the initial handlePageTransition call on page load
		oNavigation.on('click', 'a', function(e, isPageLoad){
			var oThis = $(this),
				sURL = oThis.attr('href'),
				oActive = oNavigation.find('a.' + sActiveClass);

			if(oThis.hasClass(sActiveClass)){
				return false;
			}

			// Swap Active Button
			oActive.removeClass(sActiveClass);
			oThis.addClass(sActiveClass);

			// Run some further validation with projects
			if(oActive.attr('href') == '/projects'){
				_Module.handleOpenProject(oActive);
			}

			// Handle Page Transitions
			_Module.handlePageTransitions(sURL);

			// Update Page URL but don't trigger on page load
			if(!isPageLoad){

				// Previously had a project open, restore full URL
				if(oThis.data('project')){
					sURL = '/projects/' + oThis.data('project');

					// Verify only 1 open project
					_Module.verifySingleOpenProject(oThis.data('project'));

					// Unset data in case user exits project page with no open project
					oThis.data('project', false);
				}

				_Module.updateURL(sURL);
			}

			e.preventDefault();
		});

		// Trigger Navigation links (i.e. internal links w/o refreshing browser)
		$('a.trigger-navigation').on('click', function(e){
			$('#navigation').find('a[href="'+ $(this).attr('href') +'"]').trigger('click');

			e.preventDefault();
		});
	},

	/**
	 * Handle History States
	 * This function holds the popstate event which resubmits the page to the router to make sure they can navigate with back/forward browser buttons.
	 */
	handleHistoryStates: function(){
		// Update navigation and page-state when navigating with back/forwards
		window.addEventListener('popstate', function(e){
			var sURL = window.location.pathname,
				oNavigation = $('#navigation'),
				oActive = oNavigation.find('a.isActive'),
				oMainPages = ['/projects', '/about', '/contact']
				isSamePage = false,
				noScrollAnimation = false,
				sHREF = false;

			// Loop through main pages to figure out where user is
			for(var i = 0; i < oMainPages.length; i++){

				// Update sHREF if its the same page
				if(sURL.indexOf(oMainPages[i]) > -1){
					sHREF = oMainPages[i];

					// Check if same page
					if(oActive.attr('href').indexOf(oMainPages[i]) > -1){
						isSamePage = true;
					}

					// Project Handling
					if(i == 0){

						// Reset filter to 'all' in case user disabled some options making current project hidden
						$('#type-all').trigger('click');

						if(sURL.indexOf('/projects/') > -1){
							var sProjectID = sURL.slice(10),
								oExpandable = $('#' + sProjectID);
							
							// Disable auto-scroll
							noScrollAnimation = true;

							// Verify only 1 open project
							Astral.router.verifySingleOpenProject(sProjectID);

							// make sure project is open
							if(!oExpandable.hasClass('open')){
								oExpandable.data('history', true).find('header.trigger').trigger('click');
							}
						}else{
							var oExpandable = $('section.expandable.open');

							// make sure projects are closed
							if(oExpandable.length > 0){
								// Disable auto-scroll
								noScrollAnimation = true;

								// make sure projects are closed
								oExpandable.data('history', true).find('header.trigger').trigger('click');
							}
						}
					}

					// Resume Handling
					if(i == 2){
						var oModalTrigger = $('#page-contact').find('a.launch-resume_modal');

						if(sURL.indexOf('resume') > -1){
							// make sure resume is opened
							oModalTrigger.trigger('click', [true]);
							
						// Make sure resume is closed
						}else{
							// Apply flag to trigger
							oModalTrigger.data('history', true);

							// close modal
							$('body > div.astral-modal-overlay').trigger('click');
						}
					}
				}
			}

			// Set to homepage if still false
			if(!sHREF){sHREF = '/';}

			// Swap active nav item if needed
			if(!isSamePage){
				oActive.removeClass('isActive');
				oNavigation.find('a[href="'+ sHREF +'"]').addClass('isActive');
			}

			// Disable autoscroll on pages such as projects(as they have built in scroll)
			if(noScrollAnimation){
				$('body').data('history', true);
			}

			// Run page transition functions
			Astral.router.handlePageTransitions(sURL);

		});
	},

	/**
	 * Handle Open Project
	 * This function runs a couple of additional checks if a project is opened
	 * 
	 * @param  {jQuery obj} oNavItem [the project link within the main navigation]
	 */
	handleOpenProject: function(oNavItem){
		var oExpandable = $('#projects').children('section.open');

		// Only proceed if a project is in fact opened
		if(oExpandable.length > 0){

			// Pause video if a the project is video based
			if(oExpandable.data('type') == 'video'){
				oExpandable.find('video')[0].pause();
			}

			// Add URL to data object of nav item
			oNavItem.data('project', oExpandable.attr('id'));
		}
	},

	/**
	 * Verify Single Open Project
	 * This function checks and force-closes a previously opened project if it exists when a user may have shortcutted their way through the navigation process via the featured projects on home page
	 * 
	 * @param  {str} id [id of projec that should be opened]
	 */
	verifySingleOpenProject: function(id){
		var oProjects = $('#projects').children('section.open');

		// Cycle thru all projects because first project found may be this one meaning later projects won't get checked
		$.each(oProjects, function(i){
			var oProject = $(this);

			// Force Close Project
			if(oProject.attr('id') != id){
				$('#highlights').removeClass('isVisible');

				oProject.removeClass('open js-animateDetails js-animateAchievements js-animateFooterItems')
					.find('header.trigger').attr({
						title: 'Click to open project',
						ariaExpanded: 'false'
					});
				oProject.children('div.content').attr('aria-hidden', 'true');
			}
		});
	},

	/**
	 * Update URL
	 * This function updates the URL with a given string so when if a user bookmarks a page it can be loaded.
	 * 
	 * @param  {str} url [string to appear after domain in URL bar]
	 */
	updateURL: function(url){
		history.pushState(null, null, url);

		Astral.router.googleAnalytics(url);
	},

	/**
	 * Google Analytics
	 * This function just fires an update to Google Analytics for tracking 'page views'
	 *
	 * @param  {str} url [string to appear after domain in URL bar]
	 */
	googleAnalytics: function(url){
		if(window.location.hostname == 'justin-herrera.com'){
			gtag('config', 'UA-15256992-1', {'page_path': url});
		}
	}
}

// Register this module with Astral
Astral.core.register({
	name: 'Page Router', // User friendly name used to reference it person-to-person
	type: 'module', // module || utility
	functionName: 'router', // Code friendly name using camelCase
	subscribers: { // add any number of custom events usting the same name/array convention found below, fill arrays with function names from the module
		'pre': [], // pre-init
		'dom': ['init'], // document ready
		'post': [], // post-init
		'load': [] // all resources loaded
	},
	dependencies: ['expandables'] // Array of strings referencing functionNames of other modules/utilities (optional)
});
