// Assign module to Astral object
Astral.logo = {
	init: function(){
		var _Module = Astral.logo;

		_Module.swapThemes();
		_Module.animation();
	},
	/**
	 * Animation
	 * Swaps around the animation class to ensure logo completes animation rather than depend on user's continued mouseover
	 */
	animation: function(){
		$('a.logo').on('mouseenter focus', function(e){
			var oThis = $(this),
				sAnimationClass = 'js-isAnimating';

			// Only run function once
			if(!oThis.hasClass(sAnimationClass)){
				oThis.addClass(sAnimationClass);

				// Set up Animation End Event Listener to clear class
				oThis.on(Astral.vars.animationEvents.end, function(e){
					// Only remove on the correct animation end event
					if(e.originalEvent.animationName == 'animateRotateY360'){
						oThis.removeClass(sAnimationClass);
					}
				});
			}
		});	
	},
	/**
	 * Swap Themes
	 * Clicking the logo swaps the highlight color theme in a set order
	 */
	swapThemes: function(){
		// Teal is default and not listed
		var oThemes = ['teal','red', 'purple', 'pink', 'blue', 'yellow', 'orange', 'green', 'white'],
			oHTML = $('html'),
			iTheme = 0, // default
			sThemeTransitioningClass = 'isThemeTransitioning';

		// Clicking triggers theme change
		$('a.logo').on('click', function(e){

			// Prevent rapid-firing theme changing causing css issues
			if(!oHTML.hasClass(sThemeTransitioningClass)){
				// Remove existing theme if present
				oHTML.removeClass('theme-' + oThemes[iTheme]);

				// Reset or add the next theme
				if(iTheme == oThemes.length - 1){
					iTheme = -1;
				}

				iTheme++;
				oHTML.addClass('theme-' + oThemes[iTheme] + ' ' + sThemeTransitioningClass);

				// Remove theme transitioning class
				setTimeout(function(){
					oHTML.removeClass(sThemeTransitioningClass);
				}, 500);
			}

			e.preventDefault();
		});
	}
}

// Register this module with Astral
Astral.core.register({
	name: 'Logo', // User friendly name used to reference it person-to-person
	type: 'module', // module || utility
	functionName: 'logo', // Code friendly name using camelCase
	subscribers: { // add any number of custom events usting the same name/array convention found below, fill arrays with function names from the module
		'pre': [], // pre-init
		'dom': ['init'], // document ready
		'post': [], // post-init
		'load': [] // all resources loaded
	},
	dependencies: [] // Array of strings referencing functionNames of other modules/utilities (optional)
});
