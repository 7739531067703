// Assign module to Astral object
Astral.modals = {
	init: function(){
		var _Module = Astral.modals;

		// Modal: Resume
		$('a.launch-resume_modal').on('click', function(e, isPageLoad){
			var oBtn = $(this),
				oTarget = $('#modal-resume'),
				oFooter = $('#footer'),
				sFooterClass = 'isAboveModal';

			// Add 'back to top' button for user's convienance
			oFooter.addClass(sFooterClass);

			// Applying a class via options set in JS
			oTarget.astralModal({
				classes: 'modal-resume',
				callbackOpened: function(){
					// Make sure wrapper has height-set on it
					_Module.handleResumeSizing();
				},
				callbackClosed: function(trigger){
					if(trigger.data('history')){
						trigger.data('history', false);
					}else{
						Astral.router.updateURL('/contact');
					}

					// Make sure these buttons are not above for other modals
					oFooter.addClass(sFooterClass);

					// remove min-height if it exists
					$('#wrapper').css('min-height', '');
				}
			});

			// Update URL but not if page loaded with resume
			if(!isPageLoad){
				Astral.router.updateURL('/contact/resume');
			}

			// If not initiated then create an orientation change event
			if(!oBtn.data('initiated')){
				oBtn.data('initiated', true);

				// Recheck resume resizing
				$(window).on('orientationchange', function(e){
					// Delayed otherwise this fires before the resizing occurs
					setTimeout(function(){
						//_Module.handleResumeSizing();
					}, 100);
				});
			}

			e.preventDefault();
		});

		// Configure IE Support Modals
		_Module.ieSupportModals();

		// Setup Mobile Navigation
		_Module.mobileNavigation();
	},

	/**
	 * Handle Resume Sizing
	 * This function compares the size of the #wrapper to the body size and if the former is larger it sets a min-height to make sure no white-space is created behind the modal.
	 */
	handleResumeSizing: function(){
		var oModal = $('body').children('div.astral-modal-container'),
			iModalHeight = oModal.outerHeight(true);

		// Due to android's issue with the body not bieng 100vh when address bar is expanded, this modal, due to its length, is often going to break by being larger than the wrapper. Here we give the wrapper a temp height to ensure it's larger
		$('#wrapper').css('min-height', (iModalHeight + oModal.offset().top) + 'px');
	},

	/**
	 * IE Support Modals
	 * This function just runs some 'special' functions when IE is detected because well... IE, even Edge is still behind.
	 */
	ieSupportModals: function(){

		// Open Old IE Warning Modal - Unsupported Browser
		if(astralDetect.browser('ie') || astralDetect.browser('ie 11')){
			$('#modal-old_ie').astralModal({
				classes: 'animated'
			});
		}

	},

	mobileNavigation: function(){
		// Set up modal opener
		$('#launch-mobile_navigation').on('click', function(e){
			var oNavigation = $('#navigation').clone().removeAttr('id'),
				oModal = $('#modal-mobile_navigation');

			// Clear and insert content with close class
			oModal.find('div.modal-content').empty().append(oNavigation).find('a').addClass('close');

			// Launch Modal
			oModal.astralModal({
				classes: 'animated'
			});

			e.preventDefault();
		});

		// Set up click-events on nav within
		$('#modal-mobile_navigation').find('div.modal-content').on('click', 'a', function(e){
			var oNavigation = $('#navigation'),
				sHREF = $(this).attr('href');

			// Trigger page
			oNavigation.find('a[href="'+ sHREF +'"]').trigger('click');

			e.preventDefault();
		});
	}
}

// Register this module with Astral
Astral.core.register({
	name: 'Modals', // User friendly name used to reference it person-to-person
	type: 'module', // module || utility
	functionName: 'modals', // Code friendly name using camelCase
	subscribers: { // add any number of custom events usting the same name/array convention found below, fill arrays with function names from the module
		'pre': [], // pre-init
		'dom': ['init'], // document ready
		'post': [], // post-init
		'load': [] // all resources loaded
	},
	dependencies: [] // Array of strings referencing functionNames of other modules/utilities (optional)
});
