// Assign module to Astral object
Astral.achievements = {
	init: function(){
		// Reload function
		$('div.comp-achievements.reloaded').on('click', 'button', function(e, onPageLoad){
			var oButton = $(this),
				oComponent = oButton.closest('div.comp-achievements'),
				oList = oComponent.find('ul'),
				oAchievements = _Achievements[oComponent.data('type')],
				tmpNumbers = Astral.achievements.generateRandomNumbers(Object.keys(oAchievements).length, oComponent.data('count')),
				tmpAchievements = [];

			// Run animation
			if(!onPageLoad){
				oButton.addClass('triggered');
			}

			// Empty list
			oList.empty();

			// Cycle through 
			$.each(tmpNumbers, function(i){
				tmpAchievements.push(Astral.achievements.generateAchievementTemplate(
					oAchievements[Object.keys(oAchievements)[tmpNumbers[i]]]
				));
			});

			// Append achievements
			oList.append(tmpAchievements);

		// Animation End (removing triggered class)
		}).on(Astral.vars.animationEvents.end, 'button', function(e){
			$(this).removeClass('triggered');
		});

		// Trigger on load
		$('div.comp-achievements.reloaded').find('button').trigger('click', [true]);
	},

	/**
	 * Generate Random Numbers
	 * This function is responsible for generating unique achievement indexes
	 * 
	 * @param  {int} max [upper threshold of number to generate based on size of object]
	 * @param {int} count [number of random numbers to generate]
	 * @return {array} [array of 3 unique numbers]
	 */
	generateRandomNumbers: function(max, count){
		var tmpNumbers = [];

		// Random function
		function getUnique(arr){
			var iRandom = Math.floor((Math.random() * (max - 1)));

			// Keep generating numbers until a new one
			if(arr.indexOf(iRandom) > -1){
				iRandom = getUnique(arr);
			}

			return iRandom;
		}

		// Get three random numbers
		for(var i = 0; i<count; i++){
			tmpNumbers.push(getUnique(tmpNumbers));
		}

		return tmpNumbers;
	},

	/**
	 * Generate Achievement Template
	 * This function 'clones' and inserts the data provided into the template.
	 * 
	 * @param  {obj} oAchievement [holds the name, title and icon class]
	 * @return {jQury obj} [HTML content to be added]
	 */
	generateAchievementTemplate: function(oAchievement){
		var oTemplate = $($('#template-achievement').html());

		// Update Text
		oTemplate.find('h6').html(oAchievement.title);
		oTemplate.find('p').html(oAchievement.description);

		// Add icon class
		oTemplate.find('span').addClass(oAchievement.icon);

		return oTemplate;
	}
}

// Register this module with Astral
Astral.core.register({
	name: 'Achievements', // User friendly name used to reference it person-to-person
	type: 'module', // module || utility
	functionName: 'achievements', // Code friendly name using camelCase
	subscribers: { // add any number of custom events usting the same name/array convention found below, fill arrays with function names from the module
		'pre': [], // pre-init
		'dom': ['init'], // document ready
		'post': [], // post-init
		'load': [] // all resources loaded
	},
	dependencies: [] // Array of strings referencing functionNames of other modules/utilities (optional)
});
