// Assign module to Astral object
Astral.quotes = {
	init: function(){
		$('div.comp-quotes').find('ul.cycle').cycle({
			autoHeight: 'calc',
			pauseOnHover: true,
			timeout: 10000,
			slides: 'li',
			pager: 'div.comp-quotes div.comp-pager ul',
			pagerTemplate: '<li><span tabindex="0"></span></li>',
			pagerActiveClass: 'active',
			log: false
		});
	}
}

// Register this module with Astral
Astral.core.register({
	name: 'Quotes', // User friendly name used to reference it person-to-person
	type: 'module', // module || utility
	functionName: 'quotes', // Code friendly name using camelCase
	subscribers: { // add any number of custom events usting the same name/array convention found below, fill arrays with function names from the module
		'pre': [], // pre-init
		'dom': ['init'], // document ready
		'post': [], // post-init
		'load': [] // all resources loaded
	},
	dependencies: [] // Array of strings referencing functionNames of other modules/utilities (optional)
});
