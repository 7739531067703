// Assign module to Astral object
Astral.expandables = {
	init: function(){
		var _Module = Astral.expandables,
			oPageProjects = $('#page-projects'),
			oFilters = $('#filters'),
			oExpandables = $('#projects'),
			oHighlights = $('#highlights');

		// Initiate Expandables
		oExpandables.astralExpandables({
			remember: false, // only works with links, will need custom setup anyway with history api
			tooltipOpened: 'Click to close project',
			tooltipClosed: 'Click to open project',
			callbackOpened: function(trigger){
				var oExpandable = trigger.closest('section'),
					oScrollMonitorDetails = oExpandable.data('smdetails'),
					oScrollMonitorAchievements = oExpandable.data('smdachievements'),
					oScrollMonitorFooter = oExpandable.data('smfooter');

				// Scroll to Expandable
				$('html,body').animate({
					scrollTop: oExpandable.offset().top - 80
				}, 250);

				// Disable all other expandables
				oExpandables.addClass('isFocused');

				// Hide Filters
				oFilters.addClass('isHiding');

				// Trigger events now that the project is loaded
				if(oExpandable.hasClass('loaded')){
					// Trigger a resize event for jquery cycle since it can't resize a hidden element
					// i.e. user opens project, closes it, resizes/flips orientation then reopens project
					// If the above is done then the gallery looks off
					$(window).trigger('resize');

					// Update Highlights
					_Module.hanadleHighlights(oExpandable.data('highlights'));

					// Add visible class and make back button tab-able
					oHighlights.addClass('isVisible').find('a').attr('indexof', 0);

					// Make Links tab-able
					oExpandable.find('div.content').find('a').attr('tabindex', 0);

					// Update URL but only when not using browser buttons
					if(oExpandable.data('history')){
						oExpandable.data('history', false);
					}else{
						// Prevent the URL updating if the user navigates away from the page while loading
						if(oPageProjects.hasClass('isActive')){
							Astral.router.updateURL('/projects/' + $(trigger).closest('section').attr('id'));
						}
					}

					// Due to the way expandables work and varrying screen-heights, manually trigger scrollmonitor's recalculation of poistion in case it is already in view(and thus triggers the animation(s))
					setTimeout(function(){
						scrollMonitor.update();
						if(oScrollMonitorDetails.isInViewport){
							oScrollMonitorDetails.callbacks.enterViewport[0].callback();
						}
						if(oScrollMonitorAchievements.isInViewport){
							oScrollMonitorAchievements.callbacks.enterViewport[0].callback();
						}
						if(oScrollMonitorFooter.isInViewport){
							oScrollMonitorFooter.callbacks.enterViewport[0].callback();
						}
					}, 250);

					// Reveal Mobile Close Project Button
					$('#btn-close_project').removeClass('hidden');
				}else{
					// Enable animation of spinner
					oExpandable.find('div.comp-loading').addClass('loading');
				}

				// Auto Play Video
				if(oExpandable.data('play') == true && oExpandable.hasClass('loaded')){
					// Reset attribute
					oExpandable.data('play', false);

					// Allow time for content to come in
					setTimeout(function(){
						oExpandable.find('video')[0].play();
					}, 250);
				}
			},
			callbackClosed: function(trigger){
				var oExpandable = $(trigger).closest('section');

				if(!oExpandable.hasClass('tmpOpen')){

					// Scroll to Expandable
					$('html,body').animate({
						scrollTop: oExpandable.offset().top - 80
					}, 250);

					// Make sure video is paused when expandable is closed
					if(oExpandable.data('type') == 'video' && oExpandable.hasClass('loaded')){
						oExpandable.find('video')[0].pause();
					}

					// Enable other expandables
					oExpandables.removeClass('isFocused');

					// Reveal Filters
					oFilters.removeClass('isHiding hidden').addClass('isRevealing');

					// Hide Highlights
					oHighlights.removeClass('isVisible').find('a').attr('tabindex', -1);

					// Make Links not tab-able
					oExpandable.find('div.content').find('a').attr('tabindex', -1);

					// Remove Scroll Monitor Animation Classes
					oExpandable.removeClass('js-animateDetails js-animateFooterItems js-animateAchievements');

					// Hide Mobile Close Project Button
					$('#btn-close_project').addClass('hidden');

					// Reset base Projects URL, but not when user is using browser buttons
					if(oExpandable.data('history')){
						oExpandable.data('history', false);
					}else{
						Astral.router.updateURL('/projects');
					}
				}
			},
			ajaxCallback: function(data, err, oTrigger){
				var oExpandable = oTrigger.closest('section'),
					oFooter = oExpandable.find('footer'),
					oProjectTemplate = $($('#template-project').html()),
					oTagsTemplate = $($('#template-tags').html());

				// Reset to project level
				data = data.project;

				// Create Project from Template
				oProjectTemplate = _Module.createProject(oProjectTemplate, data);

				// Generate and append tags if present
				if(data.tags != false){
					// Create Tags from Template
					oTagsTemplate = _Module.createTags(oTagsTemplate, data.tags);

					// Append tags
					oFooter.append(oTagsTemplate);

					// Enable Tag Info
					_Module.handleTagInfo(oExpandable);
				}else{
					oFooter.addClass('hasNoTags');
				}

				// Mobile highlights button
				oExpandable.on('click', 'button.launch-modal_highlights', function(e){
					var oExpandable = $(this).closest('section.expandable'),
						oHighlights = $('#highlights').find('div.comp-text'),
						oModal = $('#modal-highlights');

					// Insert content  
					oModal.find('div.modal-content').empty().append(oHighlights.html());

					// Pause Video if need be
					if(oExpandable.find('video').length == 1){
						oExpandable.find('video')[0].pause();
					}

					// Open Modal
					oModal.astralModal({
						classes: 'highlights animated'
					});

					e.preventDefault();
				});

				// Attach highlights data to expandable
				oExpandable.data('highlights', data.highlights);

				// Verify images are loaded and go from there
				_Module.checkAssetsLoad(oExpandable, oProjectTemplate);
			},
			ajaxType: 'json',
			ajaxCustomHandling: true
		});

		// Prevent closing when project is loaded and proceeding through automated steps
		oExpandables.find('header').on('click', function(e){
			var oExpandable = $(this).closest('section.expandable');

			if(oExpandable.hasClass('tmpOpen') && typeof e.originalEvent != 'undefined'){
				e.stopPropagation();
			}
		})
		// Open/Close with 'enter' since it is not a link which doesn't activate normally to enter
		.on('keyup', function(e){
			if(e.originalEvent.keyCode == 13){
				$(this).trigger('click');
			}
		});

		// Play Video Buttons
		oExpandables.find('section.expandable').on('click', 'a.play', function(e){
			var oBtn = $(this),
				oExpandable = oBtn.closest('section');

			// Open Expandable
			if(!oExpandable.hasClass('open')){
				// Update Attribute for playing
				oExpandable.data('play', true);

				oExpandable.find('header.trigger').trigger('click');
			}else{

				// Scroll to Expandable
				$('html,body').animate({
					scrollTop: oExpandable.offset().top - 80
				}, 250);

				// Play video
				oExpandable.find('video')[0].play();
			}

			e.preventDefault();
		});

		// Back button
		$('a.trigger-close_expandable').on('click', function(e){
			oExpandables.find('section.open.loaded').find('header.trigger').trigger('click');

			e.preventDefault();
		});

		// Initiate Other Functions
		Astral.expandables.handleAnimationEvents();		
	},

	/**
	 * Create Project
	 * This function takes data and injects it into the template
	 *
	 * @param {jQuery obj} oTemplate [HTML template for data to be injected into]
	 * @param {JSON} data [project data from project json file]
	 * @return {jQuery obj} [same HTML template given but with the new data injected in]
	 */
	createProject: function(oTemplate, data){
		var sProjectPath = '/media/projects/',
			sPathID = data.id,
			oGallery = oTemplate.find('ul.cycle'),
			oDetails = oTemplate.find('div.comp-text'),
			oSlideTemplate = oGallery.find('li'),
			oSlides = [],
			oVideo = oTemplate.find('video').parent(), // unsure why can't target div.comp-video
			oInfo = data.info,
			oAchievementContainer = oTemplate.find('div.comp-achievements').find('ul'),
			oAchievements = [];

		// Slider (websites only)
		if(data.type == 'website'){

			// Cycle thru images and create a slider
			$.each(data.slider, function(i){
				var oImg = data.slider[i],
					tmpTemplate = oSlideTemplate.clone();

				// Handle link, remove or update
				if(data.link || oImg.link){
					// Make sure specific slide doesn't have its own link
					if(oImg.link){
						tmpTemplate.find('a').attr('href', oImg.link);
					}else{
						tmpTemplate.find('a').attr('href', data.link);
					}
				}else{
					tmpTemplate.find('img').unwrap();
				}

				// Update link and image
				tmpTemplate.find('img').attr({
					src: sProjectPath + sPathID + '/' + (i < 9 ? '0' : '') + (parseInt(i) + 1) + '.jpg',
					alt: oImg.alt
				});

				// Add to array
				oSlides.push(tmpTemplate);

				// Remove template and add new slides
				oGallery.empty().append(oSlides);
			});
		// Video
		}else{
			/*
				keeping here in case safari screws up again
			 var oVideoTemplate = $('<video controls preload="metadata"></video>');

			// Update poster
			oVideoTemplate.attr('poster', sProjectPath + sPathID + '/' + 'poster.jpg');

			// Append sources
			oVideoTemplate.append('<source src="'+ sProjectPath + sPathID + '/' + sPathID + '.mp4" type="video/mp4" />');
			oVideoTemplate.append('<source src="'+ sProjectPath + sPathID + '/' + sPathID + '.ogg" type="video/ogg" />');
			oVideoTemplate.append('<source src="'+ sProjectPath + sPathID + '/' + sPathID + '.webm" type="video/webm" />');

			// Append video template to container
			oVideo.empty().append(oVideoTemplate);*/

			// Update poster
			oVideo.find('video').attr('poster', sProjectPath + sPathID + '/' + 'poster.jpg');

			// Update sources
			oVideo.find('source:eq(0)').attr('src', sProjectPath + sPathID + '/' + sPathID + '.mp4')
				.next().attr('src', sProjectPath + sPathID + '/' + sPathID + '.ogg')
				.next().attr('src', sProjectPath + sPathID + '/' + sPathID + '.webm');

			// Safari requires a force load
			oVideo.find('video')[0].load();
		}

		// Info
		oDetails.html(oInfo.content);
		oTemplate.find('div.clipart').html(oInfo.clipart);

		// Remove tall if only one p tag(meaning short details)
		if(oDetails.find('p').length == 1){
			oDetails.removeClass('tall');
		}

		// Achievements
		for(var i = 0; i < data.achievements.length; i++){
			var oAchievement = _Achievements.project[data.achievements[i]]

			// Add to array
			oAchievements.push(
				Astral.achievements.generateAchievementTemplate(oAchievement)
			);
		}

		// Remove template and add new slides
		oAchievementContainer.empty().append(oAchievements);

		// Make links not tab-able for now
		oTemplate.find('a').attr('tabindex', -1);

		return oTemplate;
	},

	/**
	 * Create Scroll Monitor Events
	 * This function creates events for Scroll Monitor that add classes to the expandable which trigger the animation of details and tags in
	 * 
	 * @param  {jQuery obj} oExpandable [the specific expandable to help track specific elements]
	 */
	createScrollMonitorEvents: function(oExpandable){
		// Scroll Monitor event when it is well w/i view(100px)
		var oScrollMonitorDetails = scrollMonitor.create(oExpandable.find('div.info'), {top: -100}),
			oScrollMonitorAchievements = scrollMonitor.create(oExpandable.find('div.comp-achievements')),
			oScrollMonitorFooter = scrollMonitor.create(oExpandable.find('footer'));

		// Animate Details In
		oScrollMonitorDetails.enterViewport(function(){
			// do not conintue if expandable is not visible or details are already animated in
			if(!oExpandable.hasClass('open') || oExpandable.hasClass('js-animateDetails')){return;}

			// Add animation class
			oExpandable.addClass('js-animateDetails');
		});

		// Animate Details In
		oScrollMonitorAchievements.enterViewport(function(){
			// do not conintue if expandable is not visible or details are already animated in
			if(!oExpandable.hasClass('open') || oExpandable.hasClass('js-animateAchievements') || !oExpandable.hasClass('js-animateDetails')){return;}

			// Add animation class
			oExpandable.addClass('js-animateAchievements');
		});

		// Animate Tags In
		oScrollMonitorFooter.enterViewport(function(){
			// do not conintue if expandable is not visible or footer is already animated in but not before details are in
			if(!oExpandable.hasClass('open') || oExpandable.hasClass('js-animateFooterItems') || !oExpandable.hasClass('js-animateDetails') || !oExpandable.hasClass('js-animateAchievements')){return;}

			// Add animation class
			oExpandable.addClass('js-animateFooterItems');
		});

		oExpandable.data({
			smdetails: oScrollMonitorDetails,
			smdachievements: oScrollMonitorAchievements,
			smfooter: oScrollMonitorFooter
		});
	},

	/**
	 * Create Tags
	 * This function adds tags, if present, to a project
	 *
	 * @param {jQuery obj} oTemplate [HTML template for data to be injected into]
	 * @param {JSON} tags [project data from project json file]
	 * @return {jQuery obj} [same HTML template given but with the new data injected in]
	 */
	createTags: function(oTemplate, tags){
		var oTagContainer = oTemplate.find('ul'),
			oTagTemplate = oTagContainer.find('li'),
			oTags = [];

		// Cycle through tags [Note: could be an array but may add to this later so an object would be needed]
		$.each(tags, function(i){
			var tmpTemplate = oTagTemplate.clone();

			// Add tooltip
			tmpTemplate.data('target', '#tooltip-' + tags[i].toLowerCase());

			// Update text
			tmpTemplate.find('span').text(tags[i]);

			// Add to array
			oTags.push(tmpTemplate);
		});

		// Remove template and add new tags
		oTagContainer.empty().append(oTags);

		return oTemplate;
	},

	/**
	 * Check Assets Load
	 * This function verifies that all assets that should be loaded are in fact loaded before proceeding to reopen an expandable
	 * 
	 * @param  {jQuery obj} oExpandable [specific expandable that needs its content checked]
	 * @param  {jQuery obj} oProjectTemplate [the project template to pass on when assets are loaded]
	 */
	checkAssetsLoad: function(oExpandable, oProjectTemplate){

		// Slider (website)
		if(oExpandable.data('type') == 'website'){
			var oImages = oProjectTemplate.find('img'),
				iImages = oImages.length,
				iCounter = 0;

			// Set up counter on expandable
			oExpandable.data('counter', 0);

			// Run through all images to verify they are loaded
			$.each(oImages, function(i){
				if(this.complete){
					iCounter++;
					oExpandable.data('counter', oExpandable.data('counter') + 1);
				}else{
					// Create one time load event/image
					$(this).one('load', function(i){
						oExpandable.data('counter', oExpandable.data('counter') + 1);

						// See if all images are loaded
						if(oExpandable.data('counter') == iImages){
							Astral.expandables.finishAJAXTransition(oExpandable, oProjectTemplate);
						}
					});
				}
			});

			// All images are already loaded, finish ajax steps
			if(iCounter == iImages){
				Astral.expandables.finishAJAXTransition(oExpandable, oProjectTemplate);
			}

		// Video
		}else{
			var oVideo = oProjectTemplate.find('video');

			// Once video is ready to be played than open up the expandable
			oVideo.on('loadeddata', function(e){
				if(this.readyState){
					Astral.expandables.finishAJAXTransition(oExpandable, oProjectTemplate);

					oVideo.off('loadeddata');
				}
			});
		}
	},

	/**
	 * Finish AJAX Transition
	 * This function fires after content, specifically images or video, and finishes up the transition from loading to actual content
	 * 
	 * @param  {jQuery obj} oExpandable [specific expandable that needs its content and styles updated]
	 * @param  {jQuery obj} oProjectTemplate [the content-injected finished template to be dropped into the expandable]
	 */
	finishAJAXTransition: function(oExpandable, oProjectTemplate){
		var	oTrigger = oExpandable.find('header');

		// Minimum wait time of 1.5 seconds for animation to finish and appear without seeming to 'glitch' out
		setTimeout(function(){

			// Proceed with an automated process
			if(oExpandable.hasClass('open')){

				// Add tmp class to keep the project 'focused' (i.e. color change on title)
				oExpandable.addClass('tmpOpen');

				// Delay closure of expandable but overlap a litle
				setTimeout(function(){
					oTrigger.trigger('click.astralExpandable');

					// Finish loading real content but only after expandable has closed
					setTimeout(function(){	
						// Update Expandable Content
						Astral.expandables.updateExpandableContent(oExpandable, oProjectTemplate);

						// Reopen Expandable
						oTrigger.trigger('click');

						// remove tmp class to ensure proper animations occur
						oExpandable.removeClass('tmpOpen');
					}, 300);
				}, 800);

			// Just update content so its there when a user opens it again
			}else{
				// Update Expandable Content
				Astral.expandables.updateExpandableContent(oExpandable, oProjectTemplate);
			}
		}, 1500);
	},

	/**
	 * Update Expandable Content
	 * This function udpates the expandable content ran either while the user is waiting or behind the scenes if they accidently opened a link and didn't want to see it.
	 * 
	 * @param  {jQuery obj} oExpandable [specific expandable that needs its content and styles updated]
	 * @param  {jQuery obj} oProjectTemplate [the content-injected finished template to be dropped into the expandable]
	 */
	updateExpandableContent: function(oExpandable, oProjectTemplate){
		var oContent = oExpandable.find('div.content');

		// Clear Content
		oContent.empty();

		// Add loaded class
		oExpandable.addClass('loaded');

		// Append template
		oContent.append(oProjectTemplate);

		// Remove Gallery/Video depending on type
		if(oExpandable.data('type') == 'website'){
			oExpandable.find('div.comp-video').remove();
		}else{
			oExpandable.find('div.comp-gallery').remove();
		}

		// Initiate jQuery Cycle 2
		Astral.expandables.initiateCycle2(oExpandable);

		// Create Scroll Monitor Event
		Astral.expandables.createScrollMonitorEvents(oExpandable);
	},

	/**
	 * Handle Animation Events
	 * This function will fire various events/functions based on the tyep of animation event that fires
	 */
	handleAnimationEvents: function(){
		// Animation End
		$('div.astral-expandables').on(Astral.vars.animationEvents.end, 'section.expandable', function(e){
			var oExpandable = $(this),
				sAnimationName = e.originalEvent.animationName;

			switch(sAnimationName){
				case 'animateHideFilteredExpandable':
					oExpandable.addClass('isHidden').removeClass('isHiding');
					break;
				case 'animateRevealFilteredExpandable':
					oExpandable.removeClass('isHidden isRevealing');
					break;
			}
		});
	},

	/**
	 * Handle Highlights
	 * This function takes an object of strings and creates list items to display at a glance
	 * 
	 * @param  {obj} data [strings to be displayed as a list]
	 */
	hanadleHighlights: function(data){
		var oList = $('#highlights').find('div.comp-text').find('ul'),
			oHighlights = [];

		// Empty previous results
		oList.empty();

		// Cycle through highlights and append
		$.each(data, function(i){
			oHighlights.push('<li>' + data[i] + '</li>');
		});

		// Update list
		oList.append(oHighlights);
	},

	/**
	 * Handle Tag Info
	 * This function creates tooltips for most users and modals on touch-enabled devices. The latter will have both in case they also have a mouse available to them.
	 * 
	 * @param  {jQuery obj} oExpandable [the specific expandable to tie events to]
	 */
	handleTagInfo: function(oExpandable){
		var oFooter = oExpandable.find('footer'),
			oTags = oFooter.find('li.hasTooltip');

		// On touch-devices, create modal (we still create tooltip because we don't know if device has mouse capability)
		if(astralDetect.feature('touch')){
			// Give ability to be focused so modal, when closed, can 'scroll' to it
			oFooter.find('li.hasTooltip').attr('tabindex', 0);

			oFooter.on('click', 'li.hasTooltip', function(e){
				var oThis = $(this),
					sText = oThis.find('span').text(),
					oModal = $('#modal-tooltip'),
					oContent = $('#tooltip-' + sText.toLowerCase()).clone().removeAttr('id');

				// Update content
				oModal.find('h4').text(sText);
				oModal.find('div.modal-content').empty().append(oContent);

				// Make Modal Appear
				oModal.astralModal({
					classes: 'animated'
				});
			});
		}else{
			// Create typical tooltip
			oTags.astralTooltip({
				arrow: false,
				direction: 'top',
				delayHide: 0,
				durationHide: 250,
				event: 'hover',
				callbackOpened: function(){
					oExpandable.addClass('hasOpenTooltip');
				},
				callbackClosed: function(){
					if(oFooter.find('li.astralTooltipHover').length == 0){
						oExpandable.removeClass('hasOpenTooltip');
					}
				}
			});
		}
	},

	/**
	 * Initiate jQuery Cycle 2
	 * This function initiates a given expandable's slider
	 * 
	 * @param  {jQuery obj} oExpandable [specific expandable that needs its jQuery cycle 2 initaited]
	 * @param  {str} sID [the id used for jQuery cycle 2]
	 */
	initiateCycle2: function(oExpandable){
		var oGallery = oExpandable.find('ul.cycle');

		// Initiate jQuery Cycle 2
		oGallery.cycle({
			autoHeight: '580:692',
			manualSpeed: 300,
			timeout: 0,
			prev: '#' + oExpandable.attr('id') + ' .btn-prev',
			next: '#' + oExpandable.attr('id') + ' .btn-next',
			slides: 'li',
			caption: '#' + oExpandable.attr('id') + ' .caption',
			swipe: true,
			log: false
		});
	}
}

// Register this module with Astral
Astral.core.register({
	name: 'Expandables', // User friendly name used to reference it person-to-person
	type: 'module', // module || utility
	functionName: 'expandables', // Code friendly name using camelCase
	subscribers: { // add any number of custom events usting the same name/array convention found below, fill arrays with function names from the module
		'pre': [], // pre-init
		'dom': ['init'], // document ready
		'post': [], // post-init
		'load': [] // all resources loaded
	},
	dependencies: [] // Array of strings referencing functionNames of other modules/utilities (optional)
});
