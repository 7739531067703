// Assign module to Astral object
Astral.contactForm = {
	init: function(){
		var _Module = Astral.contactForm;

		// Initialize Custom Selects
		$('div.astral-select').on('keydown', 'button.toggle', function(e){
			if(e.originalEvent.keyCode == 9){
				$(this).siblings('select').trigger('focusout');
			}
		}).astralSelect({
			callback: function(trigger){
				trigger.siblings('select').trigger('focusout');
			},
			timeOpen: 350,
			timeClose: 250
		});

		// Form Validation
		$('form').on('keyup focusout', 'input, select, textarea', function(e){
			var oField = $(this),
				oListItem = oField.closest('li'),
				oForm = oListItem.closest('form'),
				oRequired = oForm.find('li.isRequired'),
				oSubmitBtn = oForm.find('button.btn'),
				isValidField = false;

			// Ignore Tab but only on keyup since there is no such thing no change
			if(e.type == 'keyup'){
				if(e.originalEvent.keyCode == 9){return false;}
			}

			// Is this a required field?
			if(oListItem.hasClass('isRequired')){
				isValidField = _Module.validateFormField(oField)
			}else{
				// exit the function
				return false;
			}

			// Add error class if this field failed validation
			if(isValidField){
				oListItem.removeClass('hasError');
			}else if(!isValidField && e.type == 'focusout'){
				oListItem.addClass('hasError');
			}

			// Activate/Deactivate Submit Button
			if(_Module.verifyRequiredFields(oRequired)){
				oSubmitBtn.prop('disabled', false);
			}else{
				oSubmitBtn.prop('disabled', true);
			}

			e.preventDefault();

		// Form Submission
		}).on('submit', function(e){
			var oSubmitBtn = $(this),
				oForm = oSubmitBtn.closest('form'),
				oRequired = oForm.find('li.isRequired');

			// Submit Form or Deactivate Submit Button
			if(_Module.verifyRequiredFields(oRequired)){

				// reCaptcha v3
				grecaptcha.ready(function(){
					// Create Validation Token
					grecaptcha.execute('6LeM2cAUAAAAAER-C1VA3oyczGpXbnY-iiqXnMfg', {action: 'validation'}).then(function(token){
						$('#form-recaptcha').val(token);

						// Prevent multiple submissions
						oSubmitBtn.prop('disabled', true);

						// submit form
						_Module.submitForm(oForm);

						// Scroll to top of page
						$('html,body').animate({
							scrollTop: 0
						}, 250);
					});
				});
			}else{
				oSubmitBtn.prop('disabled', true);
			}

			e.preventDefault();
		});
	},

	/**
	 * Append Form Errors
	 * This function takes an array and adds li to the form's error section
	 *
	 * @param  {array} oErrors [strs of errors]
	 */
	appendFormErrors: function(oErrors){
		var oList = $('#form-errors').find('ul'),
			oAppendErrors = [];

		// Clear existing errors
		oList.empty();

		// Cycle thru errors and add them to the list
		$.each(oErrors, function(i){
			var oError = oErrors[i];

			if(oError.length > 1){
				oAppendErrors.push('<li>' + oError + '</li>')
			}
		});

		// Append error list
		oList.append(oAppendErrors);
	},

	/**
	 * Submit Form
	 * This function handles the ajax and misc display things on submit
	 * 
	 * @param  {jQuery obj} oForm [the specific form being submitted]
	 */
	submitForm: function(oForm){
		var oData = oForm.serialize(),
			oLoading = $($('#template-sending_email').html()),
			oFormErrors = $('#form-errors'),
			oFormSuccess = $('#form-success');

		// Remove previous errors
		oFormErrors.slideUp(250);

		// Replace cool SVG spinners with lame spinner since Edge can't animate SVG properly
		if(astralDetect.browser('edge')){
			oLoading.find('span.spinner').empty().html(Astral.vars.edgeSpinner);
		}

		// Insert Loading
		oLoading.insertBefore(oForm);

		// Fade Out Form
		oForm.addClass('isProcessing');

		// Delay 'load in' animation
		setTimeout(function(){
			oLoading.addClass('loading');
		}, 200);

		// Perform AJAX Call
		$.ajax({
			type: "POST",
			url: oForm.attr('action'),
			data: oData,
			dataType: 'json',
			success: function(data){
				// Delay in case instant response (causing animation to screw up)
				setTimeout(function(){
					oLoading.addClass('loaded');

					if(data.success){
						oForm.addClass('isCompleted');

						// Delay for animation
						setTimeout(function(){
							oForm.siblings('h1').text('Thanks!');
							oFormSuccess.slideDown(250);
							oLoading.add(oForm).remove();
						}, 650);

					}else{
						oForm.removeClass('isProcessing');

						// Delay for animation
						setTimeout(function(){
							oLoading.remove();
							oFormErrors.slideDown(250);
							Astral.contactForm.appendFormErrors(data.errors.split('<br />'));
						}, 650);
					}

				}, 2000);
			},
			error: function(){
				// Delay in case instant response (causing animation to screw up)
				setTimeout(function(){
					// Reset Classes
					oLoading.addClass('loaded');
					oForm.removeClass('isProcessing');

					// Delay for animation
					setTimeout(function(){
						oLoading.remove();
						oFormErrors.slideDown(250);

						// Append Form Errros
						Astral.contactForm.appendFormErrors(['The hamster on the server is fixing its wheel; please try resubmitting the form.']);
					}, 650);
				}, 2000);
			}
		});
	},

	/**
	 * Verify Required Fields
	 * This function just checks that all required fields are 'valid'
	 * 
	 * @param  {jQuery obj} oRequired [fields to be validated]
	 * @return {bool} [returns true if all required fields are verified otherwise false]
	 */
	verifyRequiredFields: function(oRequired){
		var iTotalRequired = oRequired.length,
			iValidFields = 0;

		// Cycle through all required fields to test
		$.each(oRequired, function(i){
			var oListItem = $(this),
				oField = oListItem.find('input, select, textarea'),
				isValid = Astral.contactForm.validateFormField(oField);

			// Increase count if valid
			if(isValid){
				iValidFields++;
			}
		});

		return iTotalRequired == iValidFields ? true : false;
	},

	/**
	 * Validate Form Field
	 * This function takes a given required field and verifies it has a value and has valid format(if needed)
	 * 
	 * @param  {jQuery obj} oField [specific field to validate]
	 * @return {bool} [returns true if field is valid otherwise false]
	 */
	validateFormField: function(oField){
		var regExEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/,
			sValue = oField.val();

		// Perform various checks based on field type
		switch(oField.attr('type')){
			case 'text':
				if(sValue != ''){
					return true;
				}
				break;
			case 'email':
				if(sValue != '' && regExEmail.test(sValue)) {
					return true;
				}
				break;
			default:
				// Select & Textarea check
				if(sValue != '-' && sValue != ''){
					return true;
				}
				break;
		}

		return false;
	}
}

// Register this module with Astral
Astral.core.register({
	name: 'Contact Form', // User friendly name used to reference it person-to-person
	type: 'module', // module || utility
	functionName: 'contactForm', // Code friendly name using camelCase
	subscribers: { // add any number of custom events usting the same name/array convention found below, fill arrays with function names from the module
		'pre': [], // pre-init
		'dom': ['init'], // document ready
		'post': [], // post-init
		'load': [] // all resources loaded
	},
	dependencies: [] // Array of strings referencing functionNames of other modules/utilities (optional)
});
